import {reactive} from 'vue'
import req from '@/lib/req'
import apis from '@/assets/apis'

export const state = reactive({

})

export const actions = {
  async txt2Img(txt) {
    return req({
      method: 'POST',
      url: apis.txt2Img,
      withCredentials: true,
      loadingLocal: true,
      data: {
        txt
      },
    });
  },
  async getTxt2ImgResult(txtId) {
    return req({
      method: 'POST',
      url: apis.getTxt2ImgResult,
      withCredentials: true,
      loadingLocal: true,
      data: {
        txtId
      },
    });
  },
}