<template>
  <div class="flex flex-col w-full items-center"
    v-loading.fullscreen.lock="loading"
    element-loading-background="rgba(0, 0, 0, 0)"
  >
    <div class="w-5/6 my-6">
      <el-input
        v-model="txt"
        :rows="5"
        type="textarea"
        placeholder="Please input"
      />
    </div>

    <el-button type="primary" @click="onTxtChange">提交</el-button>

    <img class="w-11/12 h-auto my-6 object-contain" v-if="img" :src="img" />
  </div>
</template>

<script>
import {ref} from 'vue'
import * as store from './store'

export default {
  setup() {
    const loading = ref(false);
    const txt = ref('');
    const img = ref('');
    let timer = null;
    let txtId = null;

    const onTxtChange = async () => {
      if (!txt.value) {
        return;
      }

      loading.value = true;
      txtId = await store.actions.txt2Img(txt.value);

      if (!txtId) {
        loading.value = false;
      } else {
        resultTimer();
      }
    }

    const resultTimer = () => {
      timer && clearInterval(timer);
      timer = setInterval(async () => {
        const res = await store.actions.getTxt2ImgResult(txtId);

        if (res && res.img) {
          img.value = res.img;
          clearInterval(timer);
          timer = null;
          loading.value = false;
        }
      }, 1000);
    }

    return {
      loading,
      txt,
      img,
      onTxtChange,
    }
  }
}
</script>

<style>

</style>